(function ($) {
    $(document).ready(function () {
        $('.footer-nav .footer-nav-menu__item a').each(function () {
            var menu_item = $(this);
            var classlist = menu_item.attr('class');
            if (classlist.includes('location-')) {
                if (!classlist.includes(`location-${ThemeJS.Variables.Ajax.current_province}`)) {
                    menu_item.parent().remove();
                }
            }
        });
        $('.mobile_menu .mobile-menu-container .menu-item').each(function () {
            var menu_item = $(this);
            var classlist = menu_item.attr('class');
            if (classlist.includes('location-')) {
                if (!classlist.includes(`location-${ThemeJS.Variables.Ajax.current_province}`)) {
                    menu_item.remove();
                }
            }
        });
        $('.header-top-nav .header-top-nav-menu__item-link').each(function () {
            var menu_item = $(this);
            var classlist = menu_item.attr('class');
            if (classlist.includes('location-')) {
                if (!classlist.includes(`location-${ThemeJS.Variables.Ajax.current_province}`)) {
                    menu_item.parent().remove();
                }
            }
        });
        $('.header-bottom-nav .menu-item').each(function () {
            var menu_item = $(this);
            var classlist = menu_item.attr('class');
            if (classlist.includes('location-')) {
                if (!classlist.includes(`location-${ThemeJS.Variables.Ajax.current_province}`)) {
                    menu_item.remove();
                }
            }
        });
    });
})(jQuery);