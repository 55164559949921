(function ($) {
  $(document).ready(function () {
    const $mobile_menu = $(".mobile_menu");
    $(".header__toggle_mobile_menu").click(function () {
      if ($(this).hasClass("open")) {
        $mobile_menu.fadeIn("fast");
      } else {
        $mobile_menu.fadeOut("fast");
      }
    });
  });
})(jQuery);
(function ($) {
  $(function () {
    $('li.menu-item-has-children button.submenu-expand').click(function (e) {
      e.preventDefault();
      $(this).toggleClass('rotate');
      $(this).parent().children('ul.sub-menu').slideToggle('slow');
    });
  });
})(jQuery);

(function ($) {
  const $body = $('body');
  const $header = $('header.header');
  const $toggle = $('button.header__toggle_mobile_menu');
  const navbarMobileMenu = $("header.header");


  // create focus trap
  const focus_trap = focusTrap.createFocusTrap(navbarMobileMenu.get(0), {
    onActivate() {
      $(this).find('.bars').toggleClass('open');

      $('button.header__toggle_mobile_menu .bars').toggleClass('open');
      $('.mobile_menu').toggleClass('open');
      $('body').toggleClass('overflow-hidden');
    },
    onDeactivate() {
      $('button.header__toggle_mobile_menu .bars').toggleClass('open');
      $(this).find('.bars').toggleClass('open');
      $('.mobile_menu').toggleClass('open');
      $('body').toggleClass('overflow-hidden');
    },
    initialFocus: $toggle.get(0),
  });




  // // toggle mobile menu on hamburger click
  $toggle.click(() => {
    $body.hasClass('overflow-hidden') ? focus_trap.deactivate() : focus_trap.activate();
  });
  // $('button.header__toggle_mobile_menu').click(function() {
  //    $(this).find('.bars').toggleClass('open');
  //    $('.mobile_menu').toggleClass('open');
  //    $('body').toggleClass('overflow-hidden');
  // });

  //   $('.smpl-location-selector-btn').click(function() {
  //  $('button.header__toggle_mobile_menu .bars').toggleClass('open');
  //  $('.mobile_menu').toggleClass('open');
  //   });

  // $('.search-field').focusin(function() {
  //    $('body').addClass('search-opened');
  // }).blur(function() {
  //    if (!$('.site-search').hasClass('opened'))
  //       $('body').removeClass('search-opened');
  // })
  // $('.search-field')
  //    .focusout(function() {})
  //    .blur(function() {
  //       $('.fa-search').removeClass('toggled');
  //    });
  // $('.fa-search').click(function() {
  //    $('.search-field').focus();
  //    $(this).addClass('toggled');
  // })
  // $('.search-field').click(function() {
  //    $('.fa-search').addClass('toggled');
  // })
  // $('button.open-the-search').click(function() {
  //    $(this).blur();
  //    $('.site-search').addClass('opened');
  //    $('body').addClass('search-opened');
  // });
  // $('button.close-the-search').click(function() {
  //    $(this).blur();
  //    $('.search-field').blur();
  //    $('.site-search').removeClass('opened');
  //    $('body').removeClass('search-opened');
  // });
})(jQuery);