let swiper = new Swiper(".mySwiper", {
    spaceBetween: 30,
    speed: 500,
    effect: "fade",
    autoplay: {
        delay: 4000,
    },
    loop: true,
    navigation: {
        nextEl: ".hero-banner-slider-wrapper-button__next",
        prevEl: ".hero-banner-slider-wrapper-button__prev",
    },
    pagination: {
        el: ".hero-banner-slider-wrapper-pagination",
        clickable: true,
    },
});
(function ($) {
    $(document).ready(function () {
        swiper.on('realIndexChange', function () {
            var swiperIndex = swiper.realIndex;
            $(`.hero-banner-slider-wrapper-slide`).fadeOut(400);
            setTimeout(function () {
                $(`.hero-banner-slider-wrapper-slide[data-slideidx="${swiperIndex}"]`).fadeIn(400);
            }, 400)
        });
        $('button.play-slideshow').click(function () {
            let isPlaying = $(this).hasClass('playing');
            if (isPlaying) {
                $(this).removeClass('playing');
                swiper.autoplay.stop();
                $('button.play-slideshow .fa-pause').fadeOut(200);
                setTimeout(function () {
                    $('button.play-slideshow .fa-play').fadeIn(200);
                }, 200)
            } else {
                $(this).addClass('playing');
                swiper.autoplay.start();
                $('button.play-slideshow .fa-play').fadeOut(200);
                setTimeout(function () {
                    $('button.play-slideshow .fa-pause').fadeIn(200);
                }, 200)
            }
        })
    });
})(jQuery);