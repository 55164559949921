jQuery(function ($) {


    // load more button click event
    $('.load-more-comments').click(function () {
        var button = $(this);

        var cpage = $(this).attr('data-cpage');
        let parent_post_id = $(this).data('parent_post_id');
        let ajaxurl = $(this).data('ajax_url');

        cpage--;

        $.ajax({
            url: ajaxurl,
            data: {
                'action': 'loadmorecomments',
                'post_id': parent_post_id,
                'cpage': cpage,
            },
            type: 'POST',
            beforeSend: function (xhr) {
                button.text(button.data('loading'));
            },
            success: function (data) {
                if (data) {
                    $('ul.post-comments').append(data);
                    button.text(button.data('text'));
                    button.attr('data-cpage', cpage);
                    if (cpage == 1)
                        button.remove();
                } else {
                    button.remove();
                }
            }
        });
        return false;
    });

    $('textarea#comment').keyup(function (e) {
        e.preventDefault();
        if ($.trim($(this).val()).length < 1) {
            $('.comments .form-submit').fadeOut();
        } else {
            $('.comments .form-submit').fadeIn();
        }
    });

});