

(function ($) {
    // Create the script tag, set the appropriate attributes
    var script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyA6XogwtUHClPObFWfT8OrUY9tH8grktd0&callback=initMap';
    script.async = true;
    var map;
    var geocoder;

    let markersOnTheMap = [];

    let circlesOnTheMap = [];

    // Attach your callback function to the `window` object
    window.initMap = function () {
        // JS API is loaded and available
        geocoder = new google.maps.Geocoder();
        let theMapElement = document.getElementById('map');
        if (theMapElement)
            map = new google.maps.Map(document.getElementById('map'), {
                center: { lat: 43.68, lng: -79.28 },
                zoom: 10,
                // zoomControl: boolean,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false
            });

    };


    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(writeCookies);
        }
    }

    function getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    // function writeCookies(position) {
    //     // SMPL_LOCATION
    //     // const apikey = "AIzaSyA6XogwtUHClPObFWfT8OrUY9tH8grktd0";
    //     console.log("called");
    //     $.ajax({
    //         type: "post",
    //         dataType: "json",
    //         url: ThemeJS.Variables.Ajax.url,
    //         data: { action: "my_region_switcher", test: true, nonce: ThemeJS.Variables.Ajax.nonce },
    //         success: function (response) {
    //             console.log(response);
    //             if (response.type == "success") {
    //                 // if (language === 'en')
    //                 // window.location = `${response.site_url}`;
    //                 console.log(response);
    //                 // else
    //                 // window.location = `${response.site_url}/${language}`;
    //             }
    //         }
    //     });
    //     // $.get({
    //     //     url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&sensor=false&key=${apikey}`, success(data) {
    //     //         // console.log(data.results[0]);
    //     //         const address_components = data.results[0].address_components;
    //     //         let province = "";
    //     //         address_components.forEach((address) => {
    //     //             if (address.types)
    //     //                 if (address.types[0] === "administrative_area_level_1")
    //     //                     province = address.long_name;
    //     //         });

    //     //         if (getCookie('SMPL_LOCATION') !== ThemeJS.Variables.Ajax.provinces.indexOf(province).toString()) {
    //     //             document.cookie = `SMPL_LOCATION=${ThemeJS.Variables.Ajax.provinces.indexOf(province)};path=/`;
    //     //             location.reload();
    //     //         }
    //     //     }
    //     // });
    // }

    // if (getCookie('SMPL_LOCATION') === "") {
    // getLocation();
    // writeCookies('ts');
    // }


    // $(document).on("mouseenter", `[data-id="${id}"]`, function () {
    // console.log('testing me');
    // });

    function codeAddress(address, markup, id, permalink) {
        // var address = document.getElementById('address').value;
        geocoder.geocode({ 'address': address }, function (results, status) {
            map.setZoom(10);
            if (status == 'OK') {
                map.setCenter(results[0].geometry.location);
                var marker = new google.maps.Marker({
                    map: map,
                    position: results[0].geometry.location,
                    animation: google.maps.Animation.DROP,
                    id: id
                });
                google.maps.event.addListener(marker, 'click', function () {
                    window.open(permalink, "_blank");
                });
                markersOnTheMap.push({ id: id, marker: marker });
                var theElement = document.getElementById(id);
                theElement.addEventListener('mouseover', () => {
                    marker.setAnimation(google.maps.Animation.BOUNCE);
                });
                theElement.addEventListener('mouseleave', () => {
                    marker.setAnimation(null);
                });
            } else {
                // alert('Geocode was not successful for the following reason: ' + status);
            }
        });
    }

    function panTo(address) {
        // var address = document.getElementById('address').value;
        geocoder.geocode({ 'address': address }, function (results, status) {
            if (status == 'OK') {
                map.setCenter(results[0].geometry.location);
                var marker = new google.maps.Marker({
                    map: map,
                    position: results[0].geometry.location
                });
                map.setZoom(18);
            } else {
                // alert('Geocode was not successful for the following reason: ' + status);
            }
        });
    }

    function addPins(address) {
        // var address = document.getElementById('address').value;
        geocoder.geocode({ 'address': address }, function (results, status) {
            if (status == 'OK') {
                // map.setCenter(results[0].geometry.location);
                var marker = new google.maps.Marker({
                    map: map,
                    position: results[0].geometry.location
                });

            } else {
                // alert('Geocode was not successful for the following reason: ' + status);
            }
        });
    }

    function deleteCircles() {
        for (let i = 0; i < markersOnTheMap.length; i++) {
            markersOnTheMap[i].marker.setMap(null);
        }
        for (let i = 0; i < circlesOnTheMap.length; i++) {
            circlesOnTheMap[i].setMap(null);
        }
        circlesOnTheMap = [];
        markersOnTheMap = [];
    }

    function addCircleAroundLocation(address) {
        // var address = document.getElementById('address').value;
        // geocoder.geocode({ 'address': address }, function (results, status) {
        // if (status == 'OK') {
        // map.setCenter(results[0].geometry.location);
        // var marker = new google.maps.Marker({
        //     map: map,
        //     position: results[0].geometry.location
        // });
        // const cityCircle = new google.maps.Circle({
        //     strokeColor: "#232f5c",
        //     strokeOpacity: 0.8,
        //     strokeWeight: 2,
        //     fillColor: "#406091",
        //     fillOpacity: 0.35,
        //     map,
        //     center: results[0].geometry.location,
        //     radius: 20 * 1000,
        // });
        // circlesOnTheMap.push(cityCircle);
        // const cityCircleCenter = new google.maps.Circle({
        //     strokeColor: "#FFF",
        //     strokeOpacity: 1,
        //     strokeWeight: 1,
        //     fillColor: "#FFF",
        //     fillOpacity: 1,
        //     map,
        //     center: results[0].geometry.location,
        //     radius: 2 * 20,
        // });
        // circlesOnTheMap.push(cityCircleCenter);
        // } else {
        // alert('Geocode was not successful for the following reason: ' + status);
        // }
        // });
    }

    // Append the 'script' element to 'head'
    document.head.appendChild(script);

    function postalFilter(postalCode) {

        if (!postalCode) {
            return false;
        }

        if (postalCode.length > 7)
            return false;

        postalCode = postalCode.toString().trim();

        // var us = new RegExp("^\\d{5}(-{0,1}\\d{4})?$");
        // var ca = new RegExp(/([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}/i);
        // var caSixChar = new RegExp(/^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] [0-9][ABCEGHJ-NPRSTV-Z][0-9]$/);
        // var caThreeChar = new RegExp(/^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z]$/);

        // if (us.test(postalCode.toString())) {
        //     return true;
        // }
        const postalCodeSix = new RegExp(/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVXY][ -]?\d[ABCEGHJKLMNPRSTVXY]\d$/i);
        const postalCodeThree = new RegExp(/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVXY]$/i);

        if (postalCodeSix.test(postalCode) || postalCodeThree.test(postalCode)) {
            console.log("true");
            return true;
        }
        return false;
    }

    $(document).ready(function () {
        setTimeout(function () {
            $('.location-finder-container-location__list-location').each(function () {
                let address = $(this).find('.location-finder-container-location__list-location-address').html();
                let title = $(this).find('.location-finder-container-location__list-location-title').data('title');
                let id = $(this).data('id');
                let permalink = $(this).find('.location-finder-container-location__list-location-link').attr('href');
                codeAddress(address, title, id, permalink);
            });
        }, 500);
        $('.location-finder-container-location__list-location')
            .click(function () {
                let address = $(this).find('.location-finder-container-location__list-location-address').html();
                panTo(address);
            });
        $('button#zip-search-submit').click(function (e) {
            e.preventDefault();
            $('.invalid-zip-alert').fadeOut();
            var button = $(this);

            let searchText = $('input#zip-search').val();

            if (postalFilter(searchText)) {
                searchText.substring
                var firstHalf = searchText.substring(0, searchText.length / 2);
                var secondHalf = searchText.substring(searchText.length / 2);
                searchText = `${firstHalf.replaceAll(' ', '')} ${secondHalf.replaceAll(' ', '')}`;
                $.ajax({
                    url: ThemeJS.Variables.Ajax.url,
                    data: {
                        'action': 'location_finder',
                        's_query': searchText
                    },
                    type: 'POST',
                    beforeSend: function (xhr) {
                        button.text(button.data('loading'));
                    },
                    success: function (data) {
                        let x = JSON.parse(data);
                        deleteCircles();
                        if (x.addresses) {
                            addCircleAroundLocation(searchText);
                            x.addresses.forEach(function ({ address, title, id, permalink }) {
                                codeAddress(address, title, id, permalink);
                            });
                            $('.location-finder-container-location__list').empty();
                            $('.location-finder-container-location__list').append(x.markup);
                        }
                        if (!x.success) {
                            $('.location-finder-container-location__list').empty();
                            $('.location-finder-container-location__list').html('<p>No results found</p>');
                        }
                    }
                });
            } else {
                $('.invalid-zip-alert').fadeIn();
            }

            return false;
        });
    });
})(jQuery);