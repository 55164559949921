(function ($) {
    $(document).ready(function () {
        $(".blog-card a:not(.blog-card-info-subinfo-cats-link)").mouseenter(function () {
            $(this).closest('.blog-card').addClass('hovered-card');
        }).mouseleave(function () {
            $(this).closest('.blog-card').removeClass('hovered-card');
            // $(this).closest('.blog-card').removeClass('hovered-card');
        });
        // $(".blog-card").mouseleave(function () {
            // if ($(this).hasClass('hovered-card'))
            // $(this).closest('.blog-card').removeClass('hovered-card');
        // })
    });
})(jQuery);