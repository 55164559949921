(function ($) {
    $(document).ready(function () {

        function getCookie(cname) {
            let name = cname + "=";
            let ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }
        $('button.language-selection-selector').click(function () {
            $('button.language-selection-selector.active').removeClass('active');
            $(this).addClass('active');
        });
        // if (!getCookie('SMPL_LOCATION'))
        $('button.location-select-modal-submit').click(function () {
            // if (getCookie('SMPL_LOCATION') !== ""){
                
            // }
            let nonce = $(this).data('nonce');
            let language = $('button.language-selection-selector.active').data('languagecode');
            let location = $('select#province_select').val();
            console.log(location);
            $.ajax({
                type: "post",
                dataType: "json",
                url: ThemeJS.Variables.Ajax.url,
                data: { action: "my_language_switcher", language: language, location: location, nonce: nonce },
                success: function (response) {
                    if (response.type == "success") {
                        // if (language === 'en')
                        window.location = `${response.site_url}`;
                        // else
                        // window.location = `${response.site_url}/${language}`;
                    }
                }
            });
        });
    });
})(jQuery);